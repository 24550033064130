import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Stack, FormControl, InputLabel, NativeSelect,
    Button,
    Grid,
    Paper,
    Typography,
    Pagination,
    Divider
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import { timeSlotMap } from "../../encoding";
import { API_URL } from "../../config";

function JudgeHomePage() {

    const { judgeId } = useParams();
    const [ searchParams ] = useSearchParams();
    const token = searchParams.get('token');
   
    return (
        <>
            <Paper sx={{
                p: 5,
                maxWidth: 400,
                textAlign: 'center',
            }}>
                
                <Stack spacing={1}>
                    <Divider textAlign="center">评委后台</Divider>
                    <Link to={`/judge/${judgeId}/timeslots?token=${token}`}>值评时间段选择</Link>
                    <Link to={`/judge/${judgeId}/evaluation?token=${token}`}>评分</Link>
                </Stack>
            </Paper>
        </>
    );
}

export default JudgeHomePage;
