import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  FormControl,
  InputLabel,
  NativeSelect,
  Button,
  Typography,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  TextField,
  Grid,
  Fade,
  FormLabel,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { API_URL } from "../../config";
import { divisionMap, roundMap, timeSlotMap } from "../../encoding";
import axios from "axios";

function EvaluationForm({ submitScore, result }) {
  const [neg, setExpanded] = useState(false);

  const [vote, setVote] = useState({
    正方论点: 60,
    正方环节: 60,
  });

  return (
    <Stack
      spacing={2}
      sx={{
        p: 1,
        border: "1px dashed black",
        marginY: 1,
      }}
    >
      <Divider textAlign="center">评分表</Divider>
      <Stack>
        <Stack direction="row" spacing={2}>
          <div>
            <b>组别：</b>
            <u>{divisionMap[result.division]}</u>
          </div>
          <div>
            <b>轮次：</b>
            <u>{roundMap[result.round]}</u>
          </div>
          <div>
            <b>分组：</b>
            <u>{result.group}组</u>
          </div>
          <div>
            <b>场次：</b>
            <u>第{result.index}场</u>
          </div>
        </Stack>
        <span>
          <b>日期：</b>
          <u>
            {new Date(result.date).toLocaleDateString("zh", {
              dateStyle: "medium",
            })}
          </u>
        </span>
        <span>
          <b>时间：</b>
          <u>{timeSlotMap[result.time]}</u>
        </span>
        <span>
          <b>辩题：</b>
          <u>{result.topic}</u>
        </span>
        <span>
          <b>正方：</b>
          <u>
            {result.affirmativeTeamName ? result.affirmativeTeamName : "N/A"}
            （一辩: {result.affirmative1Name ? result.affirmative1Name : "N/A"}
            ， 二辩: {result.affirmative2Name ? result.affirmative2Name : "N/A"}
            ， 三辩: {result.affirmative3Name ? result.affirmative3Name : "N/A"}
            ）
          </u>
        </span>
        <span>
          <b>反方：</b>
          <u>
            {result.negativeTeamName ? result.negativeTeamName : "N/A"}
            （一辩: {result.negative1Name ? result.negative1Name : "N/A"}，
            二辩: {result.negative2Name ? result.negative2Name : "N/A"}， 三辩:{" "}
            {result.negative3Name ? result.negative3Name : "N/A"}）
          </u>
        </span>
      </Stack>
      <Divider />
      <Stack
        component="form"
        spacing={2}
        // alignItems='center'
        onSubmit={(e) => submitScore(e, result.id)}
      >
        
          <b>论点票分数（两方相加为120分）</b>
          <Stack direction="row" alignItems="center" justifyContent='space-evenly'>
            <InputLabel variant="standard">正方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              name="正方论点"
              value={vote.正方论点}
              onChange={(e) => setVote({ ...vote, 正方论点: e.target.value })}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 120,
                  step: 1,
                }
              }}
              required
            />
            <InputLabel variant="standard">反方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              value={120 - vote.正方论点}
              disabled
            />
          </Stack>
          <b>环节票分数（两方相加为120分）</b>
          <Stack direction="row" alignItems="center" justifyContent='space-evenly'>
            <InputLabel variant="standard">正方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              name="正方环节"
              value={vote.正方环节}
              onChange={(e) => setVote({ ...vote, 正方环节: e.target.value })}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 120,
                  step: 1,
                }
              }}
              required
            />
            <InputLabel variant="standard">反方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              value={120 - vote.正方环节}
              disabled
            />
          </Stack>
          <b>总分数票</b>
          <Stack direction="row" alignItems="center" justifyContent='space-evenly'>
            <InputLabel variant="standard">正方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              name="正方环节"
              value={Number(vote.正方环节) + Number(vote.正方论点)}
              disabled
            />
            <InputLabel variant="standard">反方</InputLabel>
            <TextField
              type="number"
              variant="standard"
              value={240 - vote.正方环节 - vote.正方论点}
              disabled
            />
          </Stack>
          <Stack direction="row" spacing={5}>
            <b>最佳辩手: </b>
            <FormControl>
              <InputLabel variant="standard">辩手</InputLabel>
              <NativeSelect name="佳辩">
                <option value={result.affirmative1}>正方一辩</option>
                <option value={result.affirmative2}>正方二辩</option>
                <option value={result.affirmative3}>正方三辩</option>
                {result.affirmative4 ? 
                  <option value={result.affirmative4}>正方四辩</option>
                : ""}
                <option value={result.negative1}>反方一辩</option>
                <option value={result.negative2}>反方二辩</option>
                <option value={result.negative3}>反方三辩</option>
                {result.negative4 ? 
                  <option value={result.negative4}>反方四辩</option>
                : ""}
              </NativeSelect>
            </FormControl>
          </Stack>
        <Button type="submit" variant="contained" fullWidth>
          提交
        </Button>
      </Stack>
    </Stack>
  );
}

function EvaluationPage() {
  const [result, setResult] = useState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const search = (e) => {
    e.preventDefault();
    const division = e.target["division"].value;
    const round = e.target["round"].value;
    const group = e.target["group"].value;
    const index = e.target["index"].value;
    fetch(
      API_URL +
        `/public/debates?division=${division}&round=${round}&group=${group}&index=${index}`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        enqueueSnackbar(error, { variant: "error" });
        setResult(null);
      } else {
        enqueueSnackbar("检索成功", { variant: "success" });
        setResult(data);
      }
    });
  };

  const submitScore = (e) => {
    e.preventDefault();

    axios.put(API_URL + `/judge/vote/${result.id}`, {
      vote: {
        "正方论点": e.target["正方论点"].value,
        "正方环节": e.target["正方环节"].value,
        "佳辩": e.target["佳辩"].value,
      },
    }).then((response) => {
      if (response.status === 200) {
        enqueueSnackbar("分数提交成功", { variant: "success" });
        setResult(null);
      } else {
        enqueueSnackbar("分数提交失败", { variant: "error" });
      }
    });
  };

  return (
    <Paper
      sx={{
        p: 3,
        minWidth: 400,
      }}
    >
      <Divider textAlign="center">
        <b>评分系统</b>
      </Divider>
      <Stack component="form" onSubmit={search}>
        <FormControl margin="dense">
          <RadioGroup
            row
            aria-labelledby="division"
            name="division"
            defaultValue="D"
          >
            <FormControlLabel
              value="D"
              control={<Radio size="small" />}
              label="大学组"
            />
            <FormControlLabel
              value="Z"
              control={<Radio size="small" />}
              label="中学组"
            />
          </RadioGroup>
        </FormControl>
        <Stack direction="row" justifyContent="space-between">
          <FormControl
            sx={{ minWidth: 100 }}
            required
            size="small"
            margin="dense"
          >
            <InputLabel id="round-select-label">轮次</InputLabel>
            <Select
              labelId="round-select-label"
              id="round"
              name="round"
              // autoWidth
              defaultValue=""
            >
              <MenuItem value="C">初赛</MenuItem>
              <MenuItem value="F">复赛</MenuItem>
              <MenuItem value="B">半决赛</MenuItem>
              <MenuItem value="J">决赛</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="group"
            label="分组(A-Z)"
            inputProps={{
              maxLength: 1,
              pattern: "[A-Z]",
            }}
            size="small"
            margin="dense"
            sx={{
              width: 110,
            }}
            required
          />
          <TextField
            id="index"
            label="场次(1-9)"
            inputProps={{
              maxLength: 1,
              pattern: "[1-9]",
            }}
            size="small"
            margin="dense"
            sx={{
              width: 110,
            }}
            required
          />
        </Stack>
        <Button
          variant={
            result &&
            result.affirmative1 &&
            result.affirmative2 &&
            result.affirmative3 &&
            result.negative1 &&
            result.negative2 &&
            result.negative3
              ? "outlined"
              : "contained"
          }
          type="submit"
        >
          检索
        </Button>
      </Stack>
      {result ? (
        result.affirmative1 &&
        result.affirmative2 &&
        result.affirmative3 &&
        result.negative1 &&
        result.negative2 &&
        result.negative3 ? (
          <EvaluationForm submitScore={submitScore} result={result} />
        ) : (
          <p>该场次评分未开放</p>
        )
      ) : (
        ""
      )}
    </Paper>
  );
}

export default EvaluationPage;
