import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  FormControl,
  Typography,
  Stack,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { timeSlotMap } from "../../encoding";

function TimeSlotAvailability() {
  const [rows, setRows] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getCapacity = () => {
    return fetch(API_URL + "/timeslots/capacities", {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (data.error) {
        const error = (data && data.error) || response.status;
        enqueueSnackbar(error, { variant: "error" });
      } else {
        setRows(data);
      }
    });
  };

  useEffect(() => {
    getCapacity();
  }, []);

  const postTimeSlotCapacity = (e) => {
    e.preventDefault();
    const date = e.target["date"].value;
    const time = e.target["time"].value;
    fetch(API_URL + `/timeslots/${date}/${time}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        capacity: e.target["capacity"].value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("值评时间段名额设置成功", { variant: "success" });
          getCapacity();
        }
      })
      .catch((error) => {
        enqueueSnackbar("值评时间段名额设置失败", { variant: "error" });
      });
  };

  return (
    <Box
      sx={{
        "> div": {
          m: 1,
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="member table">
          <TableHead>
            <TableRow>
              <TableCell>日期</TableCell>
              <TableCell>时间</TableCell>
              <TableCell>名额</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                <TableCell>{timeSlotMap[row.time]}</TableCell>
                <TableCell>{row.capacity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper
        sx={{
          display: "block",
          p: 3,
          maxWidth: 500,
        }}
      >
        <Typography variant="h5">值评时间段名额设置</Typography>
        <Stack component="form" spacing={1} onSubmit={postTimeSlotCapacity}>
          <Input
            type="date"
            id="date"
            variant="standard"
            inputProps={{
              min: "2024-01-01",
              max: "2024-12-31",
            }}
            required
          />
          <FormControl sx={{ minWidth: 100 }} required>
            <InputLabel id="time-select-label">时间段</InputLabel>
            <Select
              labelId="time-select-label"
              id="time"
              name="time"
              autoWidth
              defaultValue=""
              variant="standard"
            >
              {Object.entries(timeSlotMap).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value + '(' + key + ')'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="capacity"
            label="名额数"
            type="number"
            variant="standard"
          />
          <p>名额数设置为1可能导致错误。</p>
          <Button type="submit" variant="outlined">
            提交
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}

export default TimeSlotAvailability;
