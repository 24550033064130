const divisionMap = {
  D: "大学组",
  // Z: "中学组",
  P: "公开组",
  X: "小学组",
  C: "初中组",
  G: "高中组",
};

const roundMap = {
  1: "初赛第一轮",
  2: "初赛第二轮",
  3: "初赛第三轮",
  F: "复赛",
  8: "八分之一决赛",
  4: "四分之一决赛",
  B: "半决赛",
  J: "决赛",
};

const timeSlotMap = {
  A: "10:00-12:00",
  B: "13:00-15:00",
  C: "15:00-17:00",
  D: "17:00-19:00",
  E: "19:00-21:00",
  F: "其他"
};

const TIME_MAP = {
  A: "10:00",
  B: "13:00",
  C: "15:00",
  D: "17:00",
  E: "19:00",
  F: "其他"
};

const TEAM_GROUP_MAP = {
  "小学": "小学组",
  "初中": "初中组",
  "高中": "高中组",
  "大学": "大学组",
  "公开": "公开组",
  "评委": "评委团",
}

const toChineseDate = (date) => {
  // console.log(date)
  const d = new Date(date);
  // console.log(d.toUTCString())
  return `${d.getUTCMonth() + 1}月${d.getUTCDate()}日`;
};

export { divisionMap, roundMap, timeSlotMap, TIME_MAP, TEAM_GROUP_MAP, toChineseDate };
