import {
  Stack,
  FormControl,
  InputLabel,
  Button,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  TextField,
  Grid,
  Box,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { API_URL } from "../config";

function VolunteerPage() {
  const [result, setResult] = useState();
  const [teamScores, setTeamScores] = useState([]);
  const [debaterScores, setDebaterScores] = useState([]);

  const { judgeId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (result?.id) {
      getDebaterScores();
      getTeamScores();
    }
  }, [result]);

  const search = (e) => {
    e.preventDefault();
    const division = e.target["division"].value;
    const round = e.target["round"].value;
    const group = e.target["group"].value;
    const index = e.target["index"].value;
    fetch(
      API_URL +
        `/public/debates?division=${division}&round=${round}&group=${group}&index=${index}`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        enqueueSnackbar(error, { variant: "error" });
        setResult(null);
      } else {
        enqueueSnackbar("检索成功", { variant: "success" });
        setResult(data);
      }
    });
  };

  const getDebaterScores = () => {
    fetch(API_URL + `/evaluations/debaters?debateId=${result.id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          let i = 0;
          for (const row of data) {
            row.id = i;
            i++;
          }
          setDebaterScores(data);
        }
      });
  };

  const getTeamScores = () => {
    fetch(API_URL + `/evaluations/teams?debateId=${result.id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          let i = 0;
          for (const row of data) {
            row.id = i;
            i++;
          }
          setTeamScores(data);
        }
      });
  };

  const debaterScoreColumns = [
    { field: "debaterName", headerName: "辩手"},
    { field: "judgeName", headerName: "评委"},
    { field: "score", headerName: "分数"},
  ];

  const teamScoreColumns = [
    { field: "teamName", headerName: "队伍" },
    { field: "judgeName", headerName: "评委" },
    { field: 'type', headerName: '票型' },
    { field: "score", headerName: "分数" },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Paper
        sx={{
          p: 3,
          minWidth: 400,

        }}
      >
        <Divider textAlign="center">
          <b>实时比赛评分查询</b>
        </Divider>
        <Stack component="form" onSubmit={search}>
          <FormControl margin="dense">
            <RadioGroup
              row
              aria-labelledby="division"
              name="division"
              defaultValue="D"
            >
              <FormControlLabel
                value="D"
                control={<Radio size="small" />}
                label="大学组"
              />
              <FormControlLabel
                value="Z"
                control={<Radio size="small" />}
                label="中学组"
              />
            </RadioGroup>
          </FormControl>
          <Stack direction="row" justifyContent="space-between">
            <FormControl
              sx={{ minWidth: 100 }}
              required
              size="small"
              margin="dense"
            >
              <InputLabel id="round-select-label">轮次</InputLabel>
              <Select
                labelId="round-select-label"
                id="round"
                name="round"
                // autoWidth
                defaultValue=""
              >
                <MenuItem value="C">循环初赛</MenuItem>
                <MenuItem value="F">循环复赛</MenuItem>
                <MenuItem value="8">八分之一决赛</MenuItem>
                <MenuItem value="4">四分之一决赛</MenuItem>
                <MenuItem value="B">半决赛</MenuItem>
                <MenuItem value="J">决赛</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="group"
              label="分组(A-Z)"
              inputProps={{
                maxLength: 1,
                pattern: "[A-Z]",
              }}
              size="small"
              margin="dense"
              sx={{
                width: 110,
              }}
              required
            />
            <TextField
              id="index"
              label="场次(1-9)"
              inputProps={{
                maxLength: 1,
                pattern: "[1-9]",
              }}
              size="small"
              margin="dense"
              sx={{
                width: 110,
              }}
              required
            />
          </Stack>
          <Button variant={result ? "outlined" : "contained"} type="submit">
            检索
          </Button>
        </Stack>
        {result ? (
          <div style={{  }}>
              <DataGrid autoHeight rows={debaterScores} columns={debaterScoreColumns} />
              <DataGrid autoHeight rows={teamScores} columns={teamScoreColumns} />
          </div>
        ) : (
          ""
        )}
      </Paper>
    </Grid>
  );
}

export default VolunteerPage;
