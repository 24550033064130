import {
  NavigateBefore,
  NavigateNext,
  Pause,
  PlayArrow,
  Settings,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Radio,
  RadioGroup,
  Divider,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Time from "./Time";
import { ELEMENTS } from "./constants";
import bellSound from "./bell.wav";
import "./index.css";
import { API_URL } from "../config";
import { divisionMap, roundMap, TIME_MAP, toChineseDate } from "../encoding";
import background from "../background3.jpg";

function Timer6() {
  const bellAudio = new Audio(bellSound);

  // a1 is the general timer for the affirmative
  // n2 is the inquiry timer for the negative
  // pause is the timer for pause
  const [times, setTimes] = useState({
    zfybll: 180,
    ffsbzy: 120,
    ffybll: 180,
    zfsbzy: 120,
    zfebbl: 120,
    ffebbl: 120,
    zfsfebdb: 120,
    ffsfebdb: 120,
    zfsbzx: 120,
    ffsbzx: 120,
    zfsbxj: 120,
    ffsbxj: 120,
    zfybxj: 120,
    ffybxj: 120,
    jtbl: 480,
    ffsbjb: 210,
    zfsbjb: 180,
  });
  // index of current element
  const [element, setElement] = useState(0);
  const [pause, setPause] = useState(true);
  const [intervalId, setIntervalId] = useState();
  const [shouldIntervalStop, setShouldIntervalStop] = useState(false);
  const [shouldSwitchPlayPause, setShouldSwitchPlayPause] = useState(false);
  // 0 is false, 1 is next, -1 is prev
  const [shouldNextElement, setShouldNextElement] = useState(0);
  const [openTitleDialog, setOpenTitleDialog] = useState(false);
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [title, setTitle] = useState("本场辩题");
  const [config, setConfig] = useState({
    division: "",
    round: "",
    group: "",
    index: "",
    topic: "",
    date: "",
    time: "",
    affirmative: "正方队伍",
    negative: "反方队伍",
    affirmativeTopic: "正方辩题",
    negativeTopic: "反方辩题",
  });
  // const currentElement = ELEMENTS[element]

  useEffect(() => {
    const keyPress = (e) => {
      if (e.key === " ") {
        setShouldSwitchPlayPause(true);
      } else if (e.key === "n") {
        setShouldNextElement(1);
      } else if (e.key === "b") {
        setShouldNextElement(-1);
      }
    };
    document.addEventListener("keydown", keyPress, false);
    return () => {
      document.removeEventListener("keydown", keyPress, false);
    };
  }, []);

  useEffect(() => {
    if (shouldIntervalStop) {
      setShouldIntervalStop(false);
      clearInterval(intervalId);
    }
  }, [shouldIntervalStop]);

  useEffect(() => {
    if (shouldSwitchPlayPause) {
      setShouldSwitchPlayPause(false);
      switchPlayPause();
    }
  }, [shouldSwitchPlayPause]);

  useEffect(() => {
    if (shouldNextElement !== 0) {
      changeElement(shouldNextElement);
      setShouldNextElement(0);
    }
  }, [shouldNextElement]);

  const countdown = (start) => {
    setTimes((prevTimes) => ({
      ...prevTimes,
      [ELEMENTS[element].responsible]:
        prevTimes[ELEMENTS[element].responsible] - 1,
    }));
    // get up-to-date times
    setTimes((nowTimes) => {
      if (nowTimes[ELEMENTS[element].responsible] === 0) {
        // cannot clear interval here because it can't get the updated intervalID
        setShouldIntervalStop(true);
        bellAudio.play();
        setPause(true);
      }
      return nowTimes;
    });
  };

  const switchPlayPause = () => {
    if (times[ELEMENTS[element].responsible] === 0) {
      // TODO: maybe add warning message
      return;
    }
    // if now is paused
    if (pause) {
      setIntervalId(setInterval(() => countdown(), 1000));
    } else {
      clearInterval(intervalId);
    }
    // place after because state doesn't update immediately
    setPause((prevPause) => !prevPause);
  };

  const pauseTime = () => {
    clearInterval(intervalId);
    setPause(true);
  };

  const changeElement = (increment) => {
    if (element + increment < 0 || element + increment >= ELEMENTS.length) {
      return;
    }
    pauseTime();
    setElement((prevElement) => prevElement + increment);
  };

  const submitSetting = (e) => {
    e.preventDefault();
    setConfig({
      affirmative: e.target["affirmative-name"].value,
      negative: e.target["negative-name"].value,
      affirmativeTopic: e.target["affirmative-topic"].value,
      negativeTopic: e.target["negative-topic"].value,
    });
    setOpenSettingDialog(false);
  };

  const search = (e) => {
    e.preventDefault();
    const division = e.target["division"].value;
    const round = e.target["round"].value;
    const group = e.target["group"].value;
    const index = e.target["index"].value;
    fetch(
      API_URL +
        `/public/debates?division=${division}&round=${round}&group=${group}&index=${index}`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        // const error = (data && data.error) || response.status;
      } else {
        setConfig({
          affirmative: data.affirmativeTeamName,
          negative: data.negativeTeamName,
          time: TIME_MAP[data.time],
          date: toChineseDate(data.date),
          division: divisionMap[data.division],
          round: roundMap[data.round],
          group: data.group + "组",
          topic: data.topic,
        });
        setOpenSettingDialog(false);
      }
    });
  };

  const defaultTheme = createTheme();

  const infoTheme = createTheme({
    palette: {
      type: "light",
      text: {
        primary: "#11342d",
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          color: "textPrimary",
          display: "inline",
          margin: "1rem",
          variant: "h6",
        },
      },
    },
  });

  const counterTheme = createTheme({
    palette: {
      type: 'light',
      background: {
        default: '#11342d',
        paper: '#154f47'
      },
      text: {
        primary: '#e6c896',
      },
      primary: {
        main: '#e6c896',
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          color: 'textPrimary',
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: 'primary',
        },
      }
    },
  });

  return (
    <div
      className="timer"
      style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}
    >
      <Grid container className="main">
        <Grid item xs={4} >
          <Stack direction="row-reverse" spacing={2} justifyContent='center'>
          <Typography variant="h3" className='vertical-text' color="#3B633B" whiteSpace='pre-wrap' fontFamily='Source Han Serif'>{config.affirmative}</Typography>
          <Typography variant="h3" className="vertical-text" color="#212427" whiteSpace='pre-wrap' fontFamily='Source Han Serif'>正方：{config.affirmativeTopic}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h1" color="#3B633B" fontWeight='400' fontFamily='Source Han Serif Bold'>{ELEMENTS[element].name.substr(0, 2)}</Typography>
          <Typography variant="h2" color="#3B633B" fontWeight='400' fontFamily='Source Han Serif Bold'>{ELEMENTS[element].name.substr(2)}</Typography>
          <br />
          <br />
          <br />
          <center>
          <ThemeProvider theme={counterTheme}>
          <Time sec={times[ELEMENTS[element].responsible]} size="h1" />
          </ThemeProvider>
          </center>
        </Grid>
        <Grid item xs={4}>
        <Stack direction="row-reverse" spacing={2} justifyContent='center'>
          <Typography variant="h3" className='vertical-text' color="#3B633B" whiteSpace='pre-wrap' fontFamily='Source Han Serif'>{config.negative}</Typography>
          <Typography variant="h3" className='vertical-text' color="#212427" whiteSpace='pre-wrap' fontFamily='Source Han Serif'>反方：{config.negativeTopic}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <div className="control">
        <ThemeProvider theme={counterTheme}>
        <IconButton
          aria-label="navigate-before"
          onClick={() => changeElement(-1)}
          onKeyUp={(e) => e.preventDefault()}
          disabled={element === 0}
        >
          <NavigateBefore className="control-icon" />
        </IconButton>
        <IconButton
          aria-label="play-pause"
          onClick={switchPlayPause}
          onKeyUp={(e) => e.preventDefault()}
        >
          {pause ? (
            <PlayArrow className="control-icon" />
          ) : (
            <Pause className="control-icon" />
          )}
        </IconButton>
        <IconButton
          aria-label="navigate-after"
          onClick={() => changeElement(1)}
          onKeyUp={(e) => e.preventDefault()}
          disabled={element === ELEMENTS.length - 1}
        >
          <NavigateNext className="control-icon" />
        </IconButton>
        </ThemeProvider>
      </div>
      <div className="setting">
        <IconButton
          aria-label="setting"
          onClick={() => {
            pauseTime();
            setOpenSettingDialog(true);
          }}
          onKeyUp={(e) => e.preventDefault()}
        >
          <Settings className="control-icon" />
        </IconButton>
      </div>
      <div>
        <ThemeProvider theme={defaultTheme}>
          <Dialog
            open={openSettingDialog}
            onClose={() => setOpenSettingDialog(false)}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>计时器设置</DialogTitle>

            <DialogContent>
              <form onSubmit={submitSetting}>
                <TextField
                  margin="dense"
                  id="affirmative-name"
                  label="正方队伍名"
                  type="text"
                  variant="standard"
                  defaultValue={config.affirmative}
                />
                <TextField
                  margin="dense"
                  id="negative-name"
                  label="反方队伍名"
                  type="text"
                  variant="standard"
                  defaultValue={config.negative}
                />
                <p>长辩题需手动换行</p>
                <br/>
                <TextField
                  margin="dense"
                  id="affirmative-topic"
                  label="正方辩题"
                  type="text"
                  multiline
                  variant="standard"
                  defaultValue={config.affirmativeTopic}
                />
                <br/>
                <TextField
                  margin="dense"
                  id="negative-topic"
                  label="反方辩题"
                  type="text"
                  multiline
                  variant="standard"
                  defaultValue={config.negativeTopic}
                />
                <Button type="submit" variant="contained" fullWidth>
                  确认
                </Button>
              </form>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={() => setOpenSettingDialog(false)}>
                取消
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Timer6;
