import { API_URL } from "./config";

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);                    

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-512', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string                  
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

async function isLoggedIn() {
  const response = await fetch(API_URL + '/auth/me', {
    method: 'GET',
    credentials: 'include',
  });
  return response.ok;
}

export {
  sha256,
  isLoggedIn,
};