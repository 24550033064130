import React from 'react';
import { AppBar, Toolbar, Typography, Button, Grid, Card, CardContent, CardActions, Container } from '@mui/material';

const mockTeamMembers = [
  {
    id: 1,
    name: '张三',
    school: 'X市Y高中',
    grade: '高二',
    qq: '123456789',
    wechat: 'zhangsan123',
    phone: '13812345678',
    email: 'zhangsan@example.com',
    debateExperience: '参加过多次校内外辩论赛，获得过省级辩论赛第三名。',
    isLead: false,
    isJudge: false,
  },
  {
    id: 2,
    name: '李四',
    school: 'A市B高中',
    grade: '高一',
    qq: '987654321',
    wechat: 'lisi456',
    phone: '13987654321',
    email: 'lisi@example.com',
    debateExperience: '校辩论队成员，有丰富的辩论赛经历。',
    isLead: true,
    isJudge: false,
  },
  // 可以根据需要添加更多队员信息
];



const TeamMemberPage = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            领队管理页面
          </Typography>
          <Button color="inherit" href="/dashboard">仪表盘</Button>
          <Button color="inherit" href="/team/member">队员管理</Button>
          <Button color="inherit" href="/team/debate">比赛管理</Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          队员信息
        </Typography>
        <Grid container spacing={3}>
          {mockTeamMembers.map((member) => (
            <Grid item xs={12} sm={6} md={4} key={member.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">{member.name}</Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">{member.school}</Typography>
                  <Typography variant="body2">年级: {member.grade}</Typography>
                  <Typography variant="body2">QQ: {member.qq}</Typography>
                  <Typography variant="body2">微信: {member.wechat}</Typography>
                  <Typography variant="body2">手机号: {member.phone}</Typography>
                  <Typography variant="body2">邮箱: {member.email}</Typography>
                  <Typography variant="body2">辩论履历: {member.debateExperience}</Typography>
                  <Typography variant="body2">是否为领队: {member.isLead ? '是' : '否'}</Typography>
                  <Typography variant="body2">是否为评委: {member.isJudge ? '是' : '否'}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">编辑</Button>
                  <Button size="small">删除</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default TeamMemberPage;
