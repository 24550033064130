import React from 'react';
import { Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid } from '@mui/material';

function AuthFramePage() {
  const theme = createTheme({});

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100vh' }}
      >
        <Outlet />
      </Grid>
    </ThemeProvider>
  );
}

export default AuthFramePage;