import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Box,
  Typography,
  Autocomplete,
  createFilterOptions,
  Grid,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { getMembers } from "../MemberPage";
import { extractFormValues } from "../utils";
import { divisionMap, roundMap, timeSlotMap } from "../../encoding";

function DebatePage() {
  const [rows, setRows] = useState([]);
  const [members, setMembers] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getDebates = () => {
    fetch(API_URL + "/debates", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          setRows(data);
        }
      });
  };

  useEffect(() => {
    getDebates();
    getMembers().then((data) => {
      setMembers(data.filter((value) => value.role === "辩手"));
    });
  }, []);

  const createDebate = (e) => {
    e.preventDefault();
    fetch(API_URL + "/debates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        extractFormValues(e, [
          "division",
          "round",
          "group",
          "index",
          "topic",
          "date",
          "time",
          "link",
          "affirmative1",
          "affirmative2",
          "affirmative3",
          "affirmative4",
          "negative1",
          "negative2",
          "negative3",
          "negative4",
        ])
      ),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("赛事创建成功", { variant: "success" });
          getDebates();
        }
      })
      .catch((error) => {
        enqueueSnackbar("赛事创建失败", { variant: "error" });
      });
    // e.target.reset();
  };

  const deleteDebate = (id) => {
    fetch(API_URL + `/debates/${id}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("赛事已删除", { variant: "success" });
          getDebates();
        }
      })
      .catch((error) => {
        enqueueSnackbar("赛事删除失败", { variant: "error" });
      });
  };

  const editDebate = (id, e) => {
    e.preventDefault();
    fetch(API_URL + `/debates/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        extractFormValues(e, [
          "group",
          "index",
          "topic",
          "date",
          "time",
          "link",
          "affirmative1",
          "affirmative2",
          "affirmative3",
          "affirmative4",
          "negative1",
          "negative2",
          "negative3",
          "negative4",
        ])
      ),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("赛事更新成功", { variant: "success" });
          getDebates();
        }
      })
      .catch((error) => {
        enqueueSnackbar("赛事更新失败", { variant: "error" });
      });
    setOpenEditDialog(false);
  };

  const openEditDebate = (row) => {
    setFormDefaultValues(row);
    setOpenEditDialog(true);
  };

  const convertDatetime = (dt) => {
    const date = new Date(dt);
    return date.toLocaleString("zh");
    // return `${date.getMonth()+1}-${date.getDate()+1} ${date.getHours()}:${date.getMinutes()}`
  };

  const findNameAndTeam = (value) => {
    const member = members.find((option) => String(option.id) === value);
    return member ? `${member.name}(${member.teamName})` : "N/A";
  };

  return (
    <Box
      sx={{
        "> div": {
          m: 1,
        },
      }}
    >
      <Box>
        <CSVLink data={rows} filename={"debates.csv"}>下载CSV文件</CSVLink>
        <TableContainer component={Paper} sx={{ whiteSpace: "nowrap" }}>
          <Table aria-label="debate table">
            <TableHead>
              <TableRow>
                <TableCell>赛事ID</TableCell>
                <TableCell>组别</TableCell>
                <TableCell>轮次</TableCell>
                <TableCell>分组</TableCell>
                <TableCell>场次</TableCell>
                <TableCell>日期</TableCell>
                <TableCell>时间</TableCell>
                <TableCell>正方</TableCell>
                <TableCell>反方</TableCell>
                <TableCell>评委</TableCell>
                <TableCell>辩题</TableCell>
                <TableCell>正方一辩</TableCell>
                <TableCell>正方二辩</TableCell>
                <TableCell>正方三辩</TableCell>
                <TableCell>正方四辩</TableCell>
                <TableCell>反方一辩</TableCell>
                <TableCell>反方二辩</TableCell>
                <TableCell>反方三辩</TableCell>
                <TableCell>反方四辩</TableCell>
                <TableCell>直播链接</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{divisionMap[row.division]}</TableCell>
                  <TableCell>{roundMap[row.round]}</TableCell>
                  <TableCell>{row.group}</TableCell>
                  <TableCell>{row.index}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>
                    {timeSlotMap[row.time]}({row.time})
                  </TableCell>
                  <TableCell>{row.affirmativeTeamName}</TableCell>
                  <TableCell>{row.negativeTeamName}</TableCell>
                  <TableCell>{row.judges}</TableCell>
                  <TableCell>{row.topic}</TableCell>
                  <TableCell>{row.affirmative1Name}</TableCell>
                  <TableCell>{row.affirmative2Name}</TableCell>
                  <TableCell>{row.affirmative3Name}</TableCell>
                  <TableCell>{row.affirmative4Name}</TableCell>
                  <TableCell>{row.negative1Name}</TableCell>
                  <TableCell>{row.negative2Name}</TableCell>
                  <TableCell>{row.negative3Name}</TableCell>
                  <TableCell>{row.negative4Name}</TableCell>
                  <TableCell>{row.link}</TableCell>
                  <TableCell>
                    <Button variant="text" onClick={() => openEditDebate(row)}>
                      编辑
                    </Button>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => deleteDebate(row.id)}
                    >
                      删除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Paper
        sx={{
          display: "block",
          p: 3,
          maxWidth: 500,
        }}
      >
        <Typography variant="h5">创建赛事</Typography>
        <Grid
          container
          component="form"
          spacing={1}
          onSubmit={createDebate}
          alignItems="center"
        >
          <Grid item xs={12}>
            <FormControl size="small">
              <RadioGroup
                row
                aria-labelledby="division"
                name="division"
                defaultValue="D"
              >
                {Object.entries(divisionMap).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={value + "(" + key + ")"}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ minWidth: 100 }} required>
              <InputLabel id="round-select-label">轮次</InputLabel>
              <Select
                labelId="round-select-label"
                id="round"
                name="round"
                autoWidth
                defaultValue=""
                variant="standard"
              >
                {Object.entries(roundMap).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value + "(" + key + ")"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="group"
              label="分组(A-Z)"
              variant="standard"
              inputProps={{
                maxLength: 1,
                pattern: "[A-Z]",
              }}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="index"
              label="场次(1-99)"
              variant="standard"
              inputProps={{
                maxLength: 2,
                pattern: "^[1-9][0-9]?$",
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField id="topic" label="辩题" variant="standard" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <Input
              type="date"
              id="date"
              variant="standard"
              inputProps={{
                min: "2023-01-01",
                max: "2023-12-31",
              }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 100 }} required>
              <InputLabel id="time-select-label">时间段</InputLabel>
              <Select
                labelId="time-select-label"
                id="time"
                name="time"
                autoWidth
                defaultValue=""
                variant="standard"
              >
                {Object.entries(timeSlotMap).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value + "(" + key + ")"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="url"
              id="link"
              label="直播链接"
              variant="standard"
              fullWidth
            />
          </Grid>

          {[
            { id: "affirmative1", label: "正方一辩" },
            { id: "affirmative2", label: "正方二辩" },
            { id: "affirmative3", label: "正方三辩" },
            { id: "affirmative4", label: "正方四辩" },
            { id: "negative1", label: "反方一辩" },
            { id: "negative2", label: "反方二辩" },
            { id: "negative3", label: "反方三辩" },
            { id: "negative4", label: "反方四辩" },
          ].map((i) => (
            <Grid item xs={6} key={i.id}>
              <Autocomplete
                id={i.id}
                options={members}
                getOptionLabel={(option) => String(option.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i.label}
                    helperText={findNameAndTeam(params.inputProps.value)}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.name}({option.teamName})
                  </li>
                )}
                filterOptions={createFilterOptions({
                  stringify: (option) => option.name + option.teamName,
                })}
              ></Autocomplete>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth>
              提交
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>编辑赛事</DialogTitle>
        <form onSubmit={(e) => editDebate(formDefaultValues.id, e)}>
          <DialogContent>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <FormControl disabled size="small">
                  <RadioGroup
                    row
                    aria-labelledby="division"
                    name="division"
                    defaultValue={formDefaultValues?.division}
                  >
                    {Object.entries(divisionMap).map(([key, value]) => (
                      <FormControlLabel
                        key={key}
                        value={key}
                        control={<Radio />}
                        label={value + "(" + key + ")"}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl disabled sx={{ minWidth: 100 }} required>
                  <InputLabel id="round-select-label">轮次</InputLabel>
                  <Select
                    labelId="round-select-label"
                    id="round"
                    name="round"
                    autoWidth
                    variant="standard"
                    defaultValue={formDefaultValues?.round}
                  >
                    {Object.entries(roundMap).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value + "(" + key + ")"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="group"
                  label="分组"
                  variant="standard"
                  defaultValue={formDefaultValues?.group}
                  inputProps={{
                    maxLength: 1,
                    pattern: "[A-Z]",
                  }}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="index"
                  label="序号"
                  variant="standard"
                  defaultValue={formDefaultValues?.index}
                  inputProps={{
                    maxLength: 2,
                    pattern: "^[1-9][0-9]?$",
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="topic"
                  label="辩题"
                  variant="standard"
                  defaultValue={formDefaultValues?.topic}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="date"
                  id="date"
                  variant="standard"
                  inputProps={{
                    min: "2023-01-01",
                    max: "2023-12-31",
                  }}
                  defaultValue={formDefaultValues?.date}
                  required
                />
              </Grid>
              <Grid>
                <FormControl sx={{ minWidth: 100 }} required>
                  <InputLabel id="time-select-label">时间段</InputLabel>
                  <Select
                    labelId="time-select-label"
                    id="time"
                    name="time"
                    autoWidth
                    defaultValue={formDefaultValues?.time}
                  >
                    {Object.entries(timeSlotMap).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value + "(" + key + ")"}
                  </MenuItem>
                ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="url"
                  id="link"
                  label="直播链接"
                  variant="standard"
                  fullWidth
                  defaultValue={formDefaultValues?.link}
                />
              </Grid>
              {[
                { id: "affirmative1", label: "正方一辩" },
                { id: "affirmative2", label: "正方二辩" },
                { id: "affirmative3", label: "正方三辩" },
                { id: "affirmative4", label: "正方四辩" },
                { id: "negative1", label: "反方一辩" },
                { id: "negative2", label: "反方二辩" },
                { id: "negative3", label: "反方三辩" },
                { id: "negative4", label: "反方四辩" },
              ].map((i) => (
                <Grid item xs={6} key={i.id}>
                  <Autocomplete
                    id={i.id}
                    options={members}
                    getOptionLabel={(option) => String(option.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i.label}
                        helperText={findNameAndTeam(params.inputProps.value)}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option.name}({option.teamName})
                      </li>
                    )}
                    filterOptions={createFilterOptions({
                      stringify: (option) =>
                        option.id + option.name + option.teamName,
                    })}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    defaultValue={
                      formDefaultValues ? { id: formDefaultValues[i.id] } : ""
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit">确认</Button>
            <Button onClick={() => setOpenEditDialog(false)}>取消</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default DebatePage;
