import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Autocomplete,
  createFilterOptions,
  Popover,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import { getMembers } from "../MemberPage";
import { timeSlotMap } from "../../encoding";
import axios from "axios";

function TimeSlotPage() {
  const [rows, setRows] = useState([]);
  const [members, setMembers] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [clickedRow, setClickedRow] = useState(null);
  const [debateOptions, setDebateOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // manual add time slot form state
  const [selectedAddJudgeId, setSelectedAddJudgeId] = useState('');
  const [selectedAddDate, setSelectedAddDate] = useState(null);
  const [selectedAddTime, setSelectedAddTime] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const getTimeSlots = () => {
    fetch(API_URL + "/timeslots", {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        enqueueSnackbar(error, { variant: "error" });
      } else {
        setRows(data);
      }
    });
  };

  const getDebateOptions = (row) => {
    console.log(row);
    fetch(API_URL + `/debates?date=${row.date}&time=${row.time}`, {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        enqueueSnackbar(error, { variant: "error" });
      } else {
        setDebateOptions(data);
      }
    });
  };

  const deleteTimeslot = (row) => {
    fetch(API_URL + "/timeslots", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        judgeId: row.judgeId,
        date: row.date,
        time: row.time,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("删除成功", { variant: "success" });
          getTimeSlots();
        }
      })
      .catch((error) => {
        enqueueSnackbar("删除失败", { variant: "error" });
      });
  };

  const editAssignedDebate = (debateId) => {
    fetch(API_URL + "/timeslots", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        debateId: debateId ? debateId : null,
        judgeId: clickedRow.judgeId,
        date: clickedRow.date,
        time: clickedRow.time,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("比赛分配成功", { variant: "success" });
          closeEditAssignedDebate();
          getTimeSlots();
        }
      })
      .catch((error) => {
        enqueueSnackbar("比赛分配失败", { variant: "error" });
      });
  };

  const submitAssignedDebate = (e) => {
    e.preventDefault();
    editAssignedDebate(e.target["debate-id"].value);
  };

  const handleAssignedDebateClick = (e, row) => {
    setAnchorEl(e.currentTarget);
    setClickedRow(row);
    getDebateOptions(row);
  };

  const closeEditAssignedDebate = () => {
    setAnchorEl(null);
  };

  const handleManualAddTimeSlot = () => {
    axios.post(API_URL + `/admin/judge/${selectedAddJudgeId}/timeslots/${selectedAddDate}/${selectedAddTime}`)
      .then((response) => {
        enqueueSnackbar('添加成功', { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.error, { variant: "error" });
      });
  };



  useEffect(() => {
    getTimeSlots();
    getMembers().then((data) => {
      setMembers(data.filter((value) => value.role === "评委"));
    });
  }, []);

  return (
    <Box
      sx={{
        "> div": {
          m: 1,
        },
      }}
    >
      <CSVLink data={rows} filename={"time-slots.csv"}>下载CSV文件</CSVLink>
      <Paper
        sx={{
          p: 1,
          m: 1,
          textAlign: "center",
        }}
      >
        <h2>手动添加值评时间段</h2>
        <p>如出现评委没有选某个时间段，但有需要将那个时间段的比赛分配给这个评委的情况，可以手动为此评委添加值评时间段，然后再分配辩论赛。</p>
          <Autocomplete
            options={members}
            getOptionLabel={(option) => String(option.id)}
            renderInput={(params) => <TextField {...params} label="评委名搜索" />}
            renderOption={(props, option) => (
              <li {...props}>
                {option.name}({option.teamName})
              </li>
            )}
            filterOptions={createFilterOptions({
              stringify: (option) => option.name + option.teamName,
            })}
            inputValue={selectedAddJudgeId}
            onInputChange={(e, newInputValue) => {
              setSelectedAddJudgeId(newInputValue);
            }}
          />
          <Input
            type="date"
            id="date"
            variant="standard"
            inputProps={{
              min: "2024-01-01",
              max: "2024-12-31",
            }}
            onChange={(e) => setSelectedAddDate(e.target.value)}
            required
          />

          <FormControl sx={{ minWidth: 100 }} required>
            <InputLabel id="time-select-label">时间段</InputLabel>
            <Select
              labelId="time-select-label"
              id="time"
              name="time"
              autoWidth
              defaultValue=""
              variant="standard"
              onChange={(e) => setSelectedAddTime(e.target.value)}
            >
              {Object.entries(timeSlotMap).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value + '(' + key + ')'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant='outlined'
            onClick={handleManualAddTimeSlot}
          >
            添加
          </Button>


          
      </Paper>
      <Autocomplete
        options={members}
        getOptionLabel={(option) => String(option.name)}
        renderInput={(params) => <TextField {...params} label="评委名搜索" />}
        renderOption={(props, option) => (
          <li {...props}>
            {option.name}({option.teamName})
          </li>
        )}
        filterOptions={createFilterOptions({
          stringify: (option) => option.name + option.teamName,
        })}
        inputValue={filterKeyword}
        onInputChange={(e, newInputValue) => {
          setFilterKeyword(newInputValue);
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="member table">
          <TableHead>
            <TableRow>
              {/* <TableCell>裁判ID</TableCell> */}
              <TableCell>姓名</TableCell>
              <TableCell>所属队伍</TableCell>
              <TableCell>日期</TableCell>
              <TableCell>时间段</TableCell>
              <TableCell>辩论赛分配</TableCell>
              <TableCell>删除</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter((value) => value.name.includes(filterKeyword))
              .map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      color={row.teamGroup === '评委' ? "darkblue" : "black"}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.teamName}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{`${timeSlotMap[row.time]} (${
                    row.time
                  })`}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color={
                        row.affirmativeTeamName || row.negativeTeamName
                          ? "primary"
                          : "warning"
                      }
                      onClick={(e) => handleAssignedDebateClick(e, row)}
                      style={{ textTransform: "none" }}
                    >
                      {row.affirmativeTeamName || row.negativeTeamName
                        ? `${row.affirmativeTeamName} v. ${row.negativeTeamName}`
                        : "未分配"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      variant="text"
                      onClick={() => deleteTimeslot(row)}
                    >
                      删除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeEditAssignedDebate}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <p>请输入或点击赛事ID，该时间段选项如下</p>
        {debateOptions.map((v, i) => (
          <p key={i}>
            <a
              onClick={() => editAssignedDebate(v.id)}
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {v.id}
            </a>{" "}
            正方 {v.affirmativeTeamName} 反方 {v.negativeTeamName};
          </p>
        ))}
        <form onSubmit={submitAssignedDebate}>
          <input name="debate-id" type="text" />
          <input type="submit" />
        </form>
      </Popover>
    </Box>
  );
}

export default TimeSlotPage;
