import React, { useState } from 'react';

import {
  Button,
  Paper,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { API_URL } from '../../config';

function SendResetPasswordEmail() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const email = e.target.email.value;

    fetch(API_URL + '/auth/send-reset-password-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar('重置密码链接已发送到您的邮箱', { variant: 'success' });
        navigate('/auth/login');
      } else {
        throw new Error('重置密码链接发送失败');
      }
    })
    .catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
    
    e.target.reset();
  };

  return (
    <Paper
      sx={{
        p: 5,
        maxWidth: 400,
        textAlign: 'center',
      }}
    >
      <Button variant="text" href="/login" sx={{ textAlign: 'left' }}>
        返回登录
      </Button>
      <form onSubmit={handleSubmit}>
        <Divider>重置密码</Divider>,
        <Typography variant="h6" gutterBottom>
          提交后，我们将发送一封包含重置密码链接的电子邮件。
        </Typography>
        <TextField
          id="email"
          label="邮箱"
          variant="outlined"
          required
          fullWidth
        />

        <Button type="submit" variant="contained" color="primary">
          重置密码
        </Button>
      </form>
    </Paper>
  );
}

export default SendResetPasswordEmail;