import React, { useState } from 'react';

import {
  Button,
  Paper,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { sha256 } from '../../utils'

import { API_URL } from '../../config';

function ResetPassword() {
  const { token } = useParams();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const password = e.target.password.value;

    fetch(API_URL + `/auth/salt?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: 'error' });
        } else {
          const salt = data.salt;
          sha256(password + salt)
            .then((saltedPassword) => {
              fetch(API_URL + '/auth/reset-password', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, password: saltedPassword }),
              })
                .then((response) => {
                  if (response.ok) {
                    enqueueSnackbar('密码重置成功', { variant: 'success' });
                    navigate('/auth/login');
                  } else {
                    throw new Error('密码重置失败');
                  }
                })
                .catch((error) => {
                  enqueueSnackbar(error.message, { variant: 'error' });
                });
            }
            );
        }
      });

  };

  return (
    <Paper
      sx={{
        p: 5,
        maxWidth: 400,
        textAlign: 'center',
      }}
    >
      <form onSubmit={handleSubmit}>
        <Divider>重置密码</Divider>
        <TextField
          id="password"
          label="新密码"
          type="password"
          autoComplete='new-password'
          required
          fullWidth
          minlength="6"
          maxlength="20"
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          提交
        </Button>
      </form>
    </Paper>
  );
}

export default ResetPassword;