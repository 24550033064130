import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, Typography, Grid, Paper, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css'

const DebateRegistrationForm = () => {
  // const [teamName, setTeamName] = useState('');
  // const [isUnion, setIsUnion] = useState('');
  // const [region, setRegion] = useState('');
  // const [group, setGroup] = useState('');
  // const [teamExperience, setTeamExperience] = useState('');
  // const [members, setMembers] = useState([{ name: '', school: '', qq: '', wechat: '', phone: '', email: '', debateExperience: '', isCompeting: true, isLead: false, isJudge: false }]);
  const [formData, setFormData] = useState({
    teamName: '',
    isUnion: false,
    region: '',
    group: '',
    teamExperience: '',
    members: [{ name: '', school: '', qq: '', wechat: '', phone: '', email: '', debateExperience: '', isCompeting: true, isLead: false, isJudge: false }]
  });
  let navigate = useNavigate();
  // const formData = useLocation().state;

  // if (formData) {
  //   console.log(formData);
  //   setTeamName(formData.teamName);
  //   setIsUnion(formData.isUnion);
  //   setRegion(formData.region);
  //   setGroup(formData.group);
  //   setTeamExperience(formData.teamExperience);
  //   setMembers(formData.members);
  // }

  // 在表单数据更新时，将其保存到localStorage
  // useEffect(() => {
  //   localStorage.setItem('formData', JSON.stringify(formData));
  // }, [formData]);

  // 在组件加载时，尝试从localStorage恢复表单数据
  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('formData'));
    if (storedFormData) {
      setFormData(storedFormData);
    }
  }, []);

  // const handleMemberChange = (index, field, value) => {
  //   const updatedMembers = [...members];
  //   updatedMembers[index][field] = value;
  //   setMembers(updatedMembers);
  // };

  const handleMemberChange = (index, field, value) => {
    setFormData( (prev) => {
      const updatedMembers = [...prev.members];
      updatedMembers[index][field] = value;
      return { ...prev, members: updatedMembers };
    }
    );
  };


  const addMember = () => {
    setFormData({ ...formData, members: [...formData.members, { name: '', school: '', qq: '', wechat: '', phone: '', email: '', debateExperience: '', isCompeting: true, isLead: false, isJudge: false }] });
  };

  const removeMember = (index) => {
    const updatedMembers = [...formData.members];
    updatedMembers.splice(index, 1);
    setFormData({ ...formData, members: updatedMembers });
  };

  const validateForm = () => {
    // validate form data, required fields, etc.
    let errorMessage = '';
    if (!formData.teamName) {
      errorMessage += '队伍名称不能为空\n';
    }
    if (!formData.region) {
      errorMessage += '报名赛区不能为空\n';
    }
    if (!formData.group) {
      errorMessage += '组别不能为空\n';
    }
    // if (!teamExperience) {
    //   errorMessage += '队伍履历不能为空\n';
    // }
    // if (members.length < 4 || members.length > 12) {
    //   errorMessage += '队伍人数必须在4至12人之间\n';
    // }
    for (let i = 0; i < formData.members.length; i++) {
      if (!formData.members[i].name || !formData.members[i].school || !formData.members[i].classYear || !formData.members[i].phone || !formData.members[i].email || !formData.members[i].wechat) {
        errorMessage += `${renderMemberTitle(i)}信息不完整\n`;
      }
    }
    if (errorMessage) {
      alert(errorMessage);
      return false;
    }
    return true;
  };


  const submitForm = () => {
    // first member is always the lead
    formData.members[0].isLead = true;
    // if 大学组, second member is always the judge
    if (formData.group === '大学组') {
      formData.members[1].isJudge = true;
    }
    
    if (!validateForm()) {
      return;
    }

    localStorage.setItem('formData', JSON.stringify(formData));

    console.log(formData);
    navigate('/team/register/confirmation', { state: { formData } });
  };

  const renderMemberTitle = (index) => {
    if (index === 0) {
      return "领队";
    } else if (formData.group === '大学组' && index === 1) {
      return "随队评委";
    } else {
      return "成员" + index;
    }
  }

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: 2 }}>
      <Paper elevation={4} sx={{ padding: 2, margin: 'auto', maxWidth: 800, flexGrow: 1 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
          2024 可持续发展青年创辩大会报名表
        </Typography>

        <Box sx={{ margin: 2, padding: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
          <Typography variant="body1" color='red'>（此处文字为测试用占位符）</Typography>
          <Typography variant="h6">注意事项：</Typography>
          <Typography variant="body1">请仔细阅读以下注意事项，确保信息填写无误。</Typography>
          <ul>
            <li>每队人数限制为4至12人。</li>
            <li>确保提供的联系信息准确无误。</li>
            <li>大学组每队需有一位随队评委，中学组无需随队评委</li>
            <li>报名截止日期为2024年x月x日。</li>
            <li>请填写前准备好所有信息，刷新页面将清空表单内容</li>
          </ul>
        </Box>

        <Grid container spacing={3} alignItems="flex-start">
          {/* Team Name, Is Union, and Region Fields */}
          <Grid item xs={12}>
            <TextField fullWidth label="队伍名称" value={formData.teamName} onChange={(e) => setFormData({ ...formData, teamName: e.target.value })} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="region-label">报名赛区</InputLabel>
              <Select
                labelId="region-label"
                value={formData.region}
                label="报名赛区"
                onChange={(e) => setFormData({ ...formData, region: e.target.value })}
              >
                <MenuItem value="华北赛区">华北赛区</MenuItem>
                <MenuItem value="华东赛区">华东赛区</MenuItem>
                <MenuItem value="华南赛区">华南赛区</MenuItem>
                <MenuItem value="综合赛区">综合赛区</MenuItem>
                <MenuItem value="海外赛区">海外赛区</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="group-label">组别</InputLabel>
              <Select
                labelId="group-label"
                value={formData.group}
                label="组别"
                onChange={(e) => setFormData({ ...formData, group: e.target.value })}
              >
                <MenuItem value="大学组">大学组</MenuItem>
                <MenuItem value="中学组">中学组</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setFormData({ ...formData, isUnion: e.target.checked })}
                />
              }
              label="是否为联队？"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="队伍履历"
              multiline
              rows={4}
              onChange={(e) => setFormData({ ...formData, teamExperience: e.target.value })}
              defaultValue={formData.teamExperience}
            />
          </Grid>

          {formData.members.map((member, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <Typography variant="h6">{renderMemberTitle(index)}信息</Typography>
              </Grid>
              <Grid item xs={4}>
                {index === 0 || formData.group === '大学组' && index === 1
                  ? <FormControlLabel
                    control={
                      <Checkbox
                        checked={member.isCompeting}
                        onChange={(e) => handleMemberChange(index, 'isCompeting', e.target.checked)}
                      />
                    }
                    label="是否为参赛队员"
                  />
                  : null
                }
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => removeMember(index)} color="error">
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="姓名（必填）"
                  required
                  onChange={(e) => handleMemberChange(index, 'name', e.target.value)}
                  value={member.name}
                />
                <TextField
                  fullWidth
                  required
                  label="学校全称（必填）"
                  onChange={(e) => handleMemberChange(index, 'school', e.target.value)}
                  value={member.school}
                />
                <TextField
                  fullWidth
                  required
                  label="年级（必填）"
                  onChange={(e) => handleMemberChange(index, 'classYear', e.target.value)}
                  value={member.classYear}
                />
                <TextField
                  fullWidth
                  label="微信（必填）"
                  required
                  onChange={(e) => handleMemberChange(index, 'wechat', e.target.value)}
                  value={member.wechat}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="手机号（必填）"
                  onChange={(e) => handleMemberChange(index, 'phone', e.target.value)}
                  value={member.phone}
                />
                <TextField
                  fullWidth
                  required
                  label="邮箱（必填）"
                  onChange={(e) => handleMemberChange(index, 'email', e.target.value)}
                  value={member.email}
                />
                <TextField
                  fullWidth
                  label="QQ"
                  onChange={(e) => handleMemberChange(index, 'qq', e.target.value)}
                  value={member.qq}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="辩论履历"
                  multiline
                  rows={4}
                  onChange={(e) => handleMemberChange(index, 'debateExperience', e.target.value)}
                  value={member.debateExperience}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button startIcon={<AddCircleOutlineIcon />} onClick={addMember}>
              添加成员
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={submitForm}>
              提交
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DebateRegistrationForm;
