import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
} from "@mui/material";

import logo from "../logo.png";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import { divisionMap, roundMap } from "../encoding";
import { LiveTv } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { TIME_MAP, toChineseDate } from "../encoding";

function Home() {
  const [debates, setDebates] = useState([]);
  const [teamScores, setTeamScores] = useState([]);
  const [debaterScores, setDebaterScores] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    fetch(API_URL + "/public/debates", {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        console.error(error);
      } else {
        setDebates(data);
      }
    });
    fetch(API_URL + "/public/team-scores", {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        console.error(error);
      } else {
        setTeamScores(data);
      }
    });
    fetch(API_URL + "/public/debater-scores", {
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.error) || response.status;
        console.error(error);
      } else {
        setDebaterScores(data);
      }
    });
  }, []);

  const isDebateEnded = (debate) => {
    return true;
  };

  return (
    <Box
      sx={{
        px: "1rem",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          img: {
            height: "10vh",
          },
        }}
      >
        <img src={logo} />
      </Box>
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            my: 2,
            p: 1,
          },
        }}
      >
        <Grid item xs={12} md={9} height="100%">
          <Paper>
            <Typography variant="h5">辩论赛事</Typography>
            <Divider />
            <TableContainer
              sx={{
                whiteSpace: "nowrap",
                maxHeight: "70vh",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">正方</TableCell>
                    {/* <TableCell></TableCell> */}
                    <TableCell align="center">反方</TableCell>
                    <TableCell align="center">辩题</TableCell>
                    <TableCell align="center">时间</TableCell>
                    <TableCell colSpan={1}>场次</TableCell>

                    <TableCell>直播</TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {debates.filter(isDebateEnded).map((d, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">
                        {d.affirmativeTeamName}
                      </TableCell>
                      {/* <TableCell>对</TableCell> */}
                      <TableCell align="center">{d.negativeTeamName}</TableCell>
                      <TableCell align="center">{d.topic}</TableCell>
                      <TableCell>{`${toChineseDate(d.date)} ${
                        TIME_MAP[d.time]
                      }`}</TableCell>
                      <TableCell>{`${divisionMap[d.division]}${
                        roundMap[d.round]
                      }${d.group}组第${d.index}场`}</TableCell>

                      <TableCell>
                        {d.link ? (
                          <a
                            href={d.link}
                            target="_blank"
                            style={{ color: theme.palette.primary.main }}
                          >
                            <LiveTv />
                          </a>
                        ) : (
                          <LiveTv />
                        )}
                      </TableCell>
                      {/* <TableCell>
                        <Button variant="text">详情</Button>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* <Paper>
            <Typography variant="h5">已结束辩论</Typography>
            <Divider />
          </Paper> */}
        </Grid>
        <Grid item xs={12} md={3} height="100%">
          <Paper>
            <Typography variant="h5">团队榜</Typography>
            <Divider />
            <TableContainer
              sx={{
                whiteSpace: "nowrap",
                maxHeight: "35vh",
              }}
            >
              <Table>
                <TableBody>
                  {teamScores.filter(isDebateEnded).map((d, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{d.name}</TableCell>
                      <TableCell align="left">{d.totalScore}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper>
            <Typography variant="h5">最佳辩手榜</Typography>
            <Divider />
            <TableContainer
              sx={{
                whiteSpace: "nowrap",
                maxHeight: "35vh",
              }}
            >
              <Table>
                <TableBody>
                  {debaterScores.filter(isDebateEnded).map((d, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{d.name}</TableCell>
                      <TableCell align="left">{d.totalScore}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
