import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  FormControl,
  InputLabel,
  NativeSelect,
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Input,
  Select,
  MenuItem,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { timeSlotMap } from "../../encoding";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";

function TimeSelect() {
  const [day, setDay] = useState("");
  const [dates, setDates] = useState([]);
  const [selected, setSelected] = useState([]);
  const [availability, setAvailability] = useState([]);
  const { judgeId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dateToString = (date) => {
    return `${date.substring(5, 7)}月${date.substring(8, 10)}日`;
  };

  useEffect(() => {
    getAvailability();
    getSelected();
  }, [day]);

  const selectTime = (t) => {
    fetch(API_URL + `/judge/mytimeslots/${day}/${t}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(async (response) => {
      if (response.ok) {
        getAvailability();
        getSelected();
      }
    });
  };

  const getAvailability = () => {
    fetch(API_URL + `/judge/timeslots`, {
      method: "GET",
      credentials: "include",
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        let date = [];
        let available = [];
        for (const row of data) {
          console.log(row);
          if (!date.includes(row.date)) {
            date.push(row.date);
            available[row.date] = [];
          }
          available[row.date][row.time] = row.available === 1;
        }
        date.sort((a, b) => a > b ? -1 : 1)
        setDates(date);
        setAvailability(available);
        console.log(date, available);
      }
    });
  };

  const getSelected = () => {
    if (day) {
      fetch(API_URL + `/judge/mytimeslots/${day}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }).then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          const arr = [];
          for (const item of data) {
            arr.push(item.time);
          }
          setSelected(arr);
        }
      });
    }
  };

  const cancelTime = (t) => {
    fetch(API_URL + `/judge/mytimeslots/${day}/${t}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then(async (response) => {
      if (!response.ok) {
        enqueueSnackbar('时间段取消失败，您可能该时段已被组委指定评分赛事，请联系组委', { variant: 'error' })
      }
      getAvailability();
      getSelected();
    });
  };

  const isAvailable = (t) => {
    return availability[day][t];
  };

  const isSelected = (t) => {
    return selected.includes(t);
  };

  const changeDay = (e) => {
    setDay(e.target.value);
  };

  return (
    <>
      <Paper
        sx={{
          p: 5,
          maxWidth: 400,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>值评时间段选择</Typography>
        <Typography sx={{ color: "#616161" }}>
          尊敬的评委前辈，请于下方填写您本轮赛程希望参评到本届大会的时间段。
          如有闲暇，请选尽可能多的时间段以方便组委安排调整，诚挚向您致谢！
        </Typography>
        <br />
        <Stack spacing={1}>
          <FormControl variant="standard">
            <InputLabel id="date-select-label">日期</InputLabel>
            <Select
              labelId="date-select-label"
              value={day}
              onChange={changeDay}
            >
              {dates.map((value) => (
                <MenuItem value={value} key={value}>
                  {dateToString(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {day
            ? Object.keys(timeSlotMap).map((time, index) => (
              <div key={index}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  columnSpacing={8}
                >
                  <Grid item>{timeSlotMap[time]}</Grid>
                  <Grid item>
                    {isSelected(time) ? (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => cancelTime(time)}
                      >
                        取消
                      </Button>
                    ) : !isAvailable(time) ? (
                      <Button variant="outlined" disabled>
                        已满
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => selectTime(time)}
                      >
                        选择
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))
            : ""}
        </Stack>
      </Paper>
    </>
  );
}

export default TimeSelect;
