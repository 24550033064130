// 中学组 2v2 团体赛制
export const ELEMENTS = [
  { name: '正方一辩申论', responsible: '正方一辩申论', initialTime: 180 },
  { name: '反方二辩质询正方一辩', responsible: '反方二辩质询正方一辩', initialTime: 180 },
  { name: '反方一辩申论', responsible: '反方一辩申论', initialTime: 180 },
  { name: '正方二辩质询反方一辩', responsible: '正方二辩质询反方一辩', initialTime: 180 },
  { name: '正方二辩申论', responsible: '正方二辩申论', initialTime: 180 },
  { name: '反方一辩质询正方二辩', responsible: '反方一辩质询正方二辩', initialTime: 180 },
  { name: '反方二辩申论', responsible: '反方二辩申论', initialTime: 180 },
  { name: '正方一辩质询反方二辩', responsible: '正方一辩质询反方二辩', initialTime: 180 },
  { name: '正方任一辩手总结', responsible: '正方任一辩手总结', initialTime: 180 },
  { name: '反方任一辩手总结', responsible: '反方任一辩手总结', initialTime: 180 }
];