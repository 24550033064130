import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';

const ConfirmationPage = () => {
  const { formData } = useLocation().state;
  const navigate = useNavigate();

  if (!formData) {
    return <Typography variant="h6" sx={{ m: 2 }}>提交错误，请返回注册页重新提交</Typography>;
  }

  const handleBack = () => {
    navigate(-1); // 返回上一页
  };

  const handleSubmitConfirmation = () => {
    console.log('Submitting data...', formData);
    // 在这里执行提交操作，例如发送数据到服务器
    // navigate('/success'); // 假设您有一个显示提交成功信息的页面
  };

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button variant="contained" onClick={handleBack} sx={{ alignSelf: 'flex-start' }}>返回</Button>
      <Paper elevation={3} sx={{ p: 2, maxWidth: 800, width: '100%', mt: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          确认报名信息
        </Typography>
        <Typography variant="h6">队伍信息：</Typography>
        <Typography variant="body1"><strong>队伍名称:</strong> {formData.teamName}</Typography>
        <Typography variant="body1"><strong>是否为联队:</strong> {formData.isUnion ? '是' : '否'}</Typography>
        <Typography variant="body1"><strong>报名赛区:</strong> {formData.region}</Typography>
        <Typography variant="body1"><strong>组别:</strong> {formData.group}</Typography>
        <Typography variant="body1"><strong>队伍履历:</strong> {formData.teamExperience}</Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>队员信息：</Typography>
        {formData.members.map((member, index) => (
          <Box key={index} sx={{ mt: 1 }}>
            <Typography variant="body2"><strong>{index + 1}. 姓名:</strong> {member.name}</Typography>
            <Typography variant="body2"><strong>学校及年级:</strong> {member.school}</Typography>
            <Typography variant="body2"><strong>QQ:</strong> {member.qq}</Typography>
            <Typography variant="body2"><strong>微信:</strong> {member.wechat}</Typography>
            <Typography variant="body2"><strong>手机号:</strong> {member.phone}</Typography>
            <Typography variant="body2"><strong>邮箱:</strong> {member.email}</Typography>
            <Typography variant="body2"><strong>辩论履历:</strong> {member.debateExperience}</Typography>
            <Typography variant="body2"><strong>是否为参赛队员:</strong> {member.isCompeting ? '是' : '否'}</Typography>
            <Typography variant="body2"><strong>是否为领队:</strong> {member.isLead ? '是' : '否'}</Typography>
            <Typography variant="body2"><strong>是否为随队评委:</strong> {member.isJudge ? '是' : '否'}</Typography>
          </Box>
        ))}

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button variant="contained" onClick={handleSubmitConfirmation}>
            确认提交
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ConfirmationPage;
