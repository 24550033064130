import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Stack,
  Autocomplete,
  getListItemUtilityClass,
  ListItem,
  createFilterOptions,
  Divider,
  Popover,
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { flexbox } from "@mui/system";
import { getTeams } from "../TeamManage";
import { CSVLink } from "react-csv";

const getMembers = () => {
  return fetch(API_URL + "/members", {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        return [];
      } else {
        return data;
      }
    });
};

function MemberPage() {
  const [rows, setRows] = useState([]);
  const [teams, setTeams] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateMembers = () => {
    getMembers().then((data) => {
      setRows(data);
    });
  };

  useEffect(() => {
    updateMembers();
    getTeams().then((data) => {
      setTeams(data);
    });
  }, []);

  const createMember = (e) => {
    e.preventDefault();
    // if school is not provided, set to team name by default
    let school = e.target["school"].value;
    if (!school && teams) {
      for (const team of teams) {
        if (String(team.id) === String(e.target["team-id"].value)) {
          school = team.name;
        }
      }
    }
    fetch(API_URL + "/members", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: e.target["name"].value,
        role: e.target["role"].value,
        teamId: e.target["team-id"].value,
        school: school,
        classYear: e.target["class-year"].value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("人员创建成功", { variant: "success" });
          updateMembers();
        }
      })
      .catch((error) => {
        enqueueSnackbar("人员创建失败", { variant: "error" });
      });
    // e.target.reset();
  };

  const deleteMember = (id) => {
    fetch(API_URL + `/members/${id}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("人员已删除", { variant: "success" });
          updateMembers();
        }
      })
      .catch((error) => {
        enqueueSnackbar("人员删除失败", { variant: "error" });
      });
  };

  const editMember = (e) => {
    e.preventDefault();
    const id = e.target["id"].value;
    fetch(API_URL + `/members/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: e.target["name"].value,
        role: e.target["role"].value,
        school: e.target["school"].value,
        classYear: e.target["class-year"].value,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("人员更新成功", { variant: "success" });
          updateMembers();
        }
      })
      .catch((error) => {
        enqueueSnackbar("人员更新失败", { variant: "error" });
      });
    setOpenEditDialog(false);
  };

  const openEditMember = (row) => {
    setFormDefaultValues(row);
    setOpenEditDialog(true);
  };




  const handleUpdateEmailClick = (e, row) => {
    setAnchorEl(e.currentTarget);
    setClickedRow(row);
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const updateEmail = (e) => {
    e.preventDefault();
    const id = clickedRow.id;
    if (!clickedRow.email) {
      fetch(API_URL + `/members/${id}/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: e.target["email"].value,
          role: clickedRow.role,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const data = await response.json();
            const error = (data && data.error) || response.status;
            enqueueSnackbar(error, { variant: "error" });
          } else {
            enqueueSnackbar("邮箱设置成功", { variant: "success" });
            handlePopoverClose();
            updateMembers();
          }
        })
        .catch((error) => {
          enqueueSnackbar("邮箱设置失败", { variant: "error" });
        });
    } else {
      fetch(API_URL + `/members/${id}/user`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: e.target["email"].value,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const data = await response.json();
            const error = (data && data.error) || response.status;
            enqueueSnackbar(error, { variant: "error" });
          } else {
            enqueueSnackbar("邮箱更新成功", { variant: "success" });
            handlePopoverClose();
            updateMembers();
          }
        })
        .catch((error) => {
          enqueueSnackbar("邮箱更新失败", { variant: "error" });
        });
    }
  }



  return (
    <Box
      sx={{
        "> div": {
          m: 1,
        },
      }}
    >
      <CSVLink data={rows} filename={"members.csv"}>下载CSV文件</CSVLink>
      <TableContainer component={Paper}>
        <Table aria-label="member table">
          <TableHead>
            <TableRow>
              <TableCell>人员ID</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>领队/队员/裁判</TableCell>
              <TableCell>队伍</TableCell>
              <TableCell>学校</TableCell>
              <TableCell>年级</TableCell>
              <TableCell>后台登录邮箱</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.teamName}({row.teamId})</TableCell>
                <TableCell>{row.school}</TableCell>
                <TableCell>{row.classYear}</TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    disabled={row.role !== "评委" && row.role !== "领队"}
                    color={row.email ? "success" : "error"}
                    onClick={(e) => handleUpdateEmailClick(e, row)}
                  >
                    {row.email ?? "未设置"}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button variant="text" onClick={() => openEditMember(row)}>
                    编辑
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => deleteMember(row.id)}
                  >
                    删除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper
        sx={{
          display: "block",
          p: 3,
          maxWidth: 500,
        }}
      >
        <Typography variant="h5">人员录入</Typography>
        <Stack component="form" spacing={1} onSubmit={createMember}>
          <TextField id="name" label="人员名称" variant="standard" required />
          <FormControl>
            <RadioGroup row aria-labelledby="role" name="role">
              <FormControlLabel value="领队" control={<Radio />} label="领队" />
              <FormControlLabel value="辩手" control={<Radio />} label="辩手" />
              <FormControlLabel value="评委" control={<Radio />} label="评委" />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            id="team-id"
            options={teams}
            getOptionLabel={(option) => String(option.id)}
            renderInput={(params) => <TextField {...params} label="队伍ID" />}
            renderOption={(props, option) => (
              <li {...props}>
                {option.name}({option.id})
              </li>
            )}
            filterOptions={createFilterOptions({
              stringify: (option) => option.id + option.name,
            })}
          ></Autocomplete>
          <TextField id="school" label="学校（留空默认为队名）" variant="standard" />
          <TextField id="class-year" label="年级" variant="standard" />
          <Button type="submit" variant="outlined">
            提交
          </Button>
        </Stack>
      </Paper>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>编辑队伍</DialogTitle>
        <form onSubmit={editMember}>
          <DialogContent>
            <TextField
              disabled
              id="id"
              label="人员ID"
              type="text"
              defaultValue={formDefaultValues?.id}
            />
            <TextField
              id="name"
              label="姓名"
              type="text"
              defaultValue={formDefaultValues?.name}
              required
            />
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="role"
                name="role"
                defaultValue={formDefaultValues?.role}
              >
                <FormControlLabel
                  value="领队"
                  control={<Radio />}
                  label="领队"
                />
                <FormControlLabel
                  value="辩手"
                  control={<Radio />}
                  label="辩手"
                />
                <FormControlLabel
                  value="评委"
                  control={<Radio />}
                  label="评委"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              disabled
              id="team-id"
              label="队伍ID"
              type="text"
              defaultValue={formDefaultValues?.teamId}
            />
            <TextField
              id="school"
              label="学校"
              type="text"
              defaultValue={formDefaultValues?.school}
            />
            <TextField
              id="class-year"
              label="年级"
              variant="standard"
              defaultValue={formDefaultValues?.classYear}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">确认</Button>
            <Button onClick={() => setOpenEditDialog(false)}>取消</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <form onSubmit={updateEmail}>
          <input name="email" type="email" required />
          <Button type="submit" variant="outlined">
            提交
          </Button>
        </form>
      </Popover>
    </Box>
  );
}

export { getMembers };
export default MemberPage;
