// 3v3 赛制
export const ELEMENTS = [{
  name: '正方一辩申论',
  responsible: 'zfybc',
  initialTime: 180,
}, {
  name: '反方二辩质询正方一辩',
  responsible: 'ffezxzfyb',
  initialTime: 180,
}, {
  name: '反方一辩申论',
  responsible: 'ffybc',
  initialTime: 180,
}, {
  name: '正方三辩质询反方一辩',
  responsible: 'zfssxzffyb',
  initialTime: 180,
}, {
  name: '正方二辩申论',
  responsible: 'zfebc',
  initialTime: 180,
}, {
  name: '反方三辩质询正方二辩',
  responsible: 'ffssxzxfeb',
  initialTime: 180,
}, {
  name: '反方二辩申论',
  responsible: 'ffebc',
  initialTime: 180,
}, {
  name: '正方一辩质询反方二辩',
  responsible: 'zfybxzffeb',
  initialTime: 180,
}, {
  name: '正方三辩申论',
  responsible: 'zfsbc',
  initialTime: 180,
}, {
  name: '反方一辩质询正方三辩',
  responsible: 'ffybxzfsb',
  initialTime: 180,
}, {
  name: '反方三辩申论',
  responsible: 'ffsbc',
  initialTime: 180,
}, {
  name: '正方二辩质询反方三辩',
  responsible: 'zfebxzffsb',
  initialTime: 180,
}, {
  name: '正方结辩',
  responsible: 'zfjb',
  initialTime: 180,
}, {
  name: '反方结辩',
  responsible: 'ffjb',
  initialTime: 180,
}]