import { Home } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Stack, FormControl, InputLabel, NativeSelect,
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Divider
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams, useSearchParams, NavLink, Outlet, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../utils";

function JudgeFramePage() {

  const navigate = useNavigate();

  useEffect(() => {
    isLoggedIn()
      .then((ok) => {
        if (!ok) {
          navigate('/auth/login');
        }
      });
  }, []);

  return (
    <>
      <NavLink
        to={`/judge/home`}
        children={
          <Home
            fontSize="large"
            htmlColor="white"
            sx={{
              position: 'absolute',
              left: '1rem',
              top: '1rem',
            }}
          />
        }
      />
      <Box sx={{
        background: '#11342d',
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: -11,
      }}></Box>
      <Box sx={{
        background: '#FFFFFF',
        position: 'fixed',
        opacity: 0.15,
        filter: 'blur(140px)',
        height: '100%',
        width: '100%',
        zIndex: -10,
      }}></Box>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100vh' }}
      >
        <Outlet />
      </Grid>
    </>
  );
}

export default JudgeFramePage;
