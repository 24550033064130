import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Container } from '@mui/material';

const mockTeamMembers = [
  { id: 1, name: '张三' },
  { id: 2, name: '李四' },
  { id: 3, name: '王五' },
  { id: 4, name: '赵六' },
  { id: 5, name: '孙七' },
  // 更多队员
];

const TeamDebateDetailPage = () => {
  const [selectedPlayers, setSelectedPlayers] = useState(Array(4).fill(""));
  const navigate = useNavigate();

  const handlePlayerChange = (index, event) => {
    const newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers[index] = event.target.value;
    setSelectedPlayers(newSelectedPlayers);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}> {/* 使用Container组件并设置最大宽度 */}
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" onClick={() => navigate(-1)}>返回</Button>
      </Box>
      <Typography variant="h4" gutterBottom>比赛详情</Typography>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6">比赛主题: 科技与隐私</Typography>
          <Typography>比赛时间: 2023-04-15 14:00</Typography>
          <Typography>地点: 腾讯会议 xxx-xxx-xxx</Typography>
          {/* 更多比赛详情 */}
        </CardContent>
      </Card>
      <Typography variant="h5" gutterBottom>选择上场队员</Typography>
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={6} key={index}>
            <FormControl fullWidth>
              <InputLabel>{`队员 ${index + 1}`}</InputLabel>
              <Select
                value={selectedPlayers[index]}
                label={`队员 ${index + 1}`}
                onChange={(e) => handlePlayerChange(index, e)}
              >
                {mockTeamMembers.map((member) => (
                  <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeamDebateDetailPage;
