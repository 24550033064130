import { useEffect, useState } from "react";

function SaltGenerate() {

  const [salt, setSalt] = useState('')
  const [password, setPassword] = useState('')
  const [salted, setSalted] = useState('')

  const generateSalt = () => {
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var lenString = 64;  
    var randomstring = '';  
    for (var i = 0; i < lenString; i++) {  
        var rnum = Math.floor(Math.random() * characters.length);  
        randomstring += characters.substring(rnum, rnum+1);  
    }
    setSalt(randomstring);
  }

  const changePassword = (e) => {
    setPassword(e.target.value);
  }

  useEffect(() => {
    async function sha256(message) {
      // encode as UTF-8
      const msgBuffer = new TextEncoder().encode(message);                    
  
      // hash the message
      const hashBuffer = await crypto.subtle.digest('SHA-512', msgBuffer);
  
      // convert ArrayBuffer to Array
      const hashArray = Array.from(new Uint8Array(hashBuffer));
  
      // convert bytes to hex string                  
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    }
    sha256(password + salt)
      .then((data) => {
        setSalted(data)
      });
  }, [password, salt])

  return (
    <>
      <p>该页面是离线页面，输入密码不会上传服务器。密码加盐+sha256后是安全的，无法还原原始密码。请先生成盐。<a href="https://zh.m.wikipedia.org/zh-hans/%E7%9B%90_(%E5%AF%86%E7%A0%81%E5%AD%A6)">原理(维基百科)</a></p>
      <p>密码：<input type="password" value={password} onChange={changePassword} /></p>
      <button onClick={generateSalt}>生成盐</button>
      <p>盐：{salt ? salt : '未生成'}</p>
      <p>加盐密码sha256：{salted}</p>
    </>
  );
}

export default SaltGenerate;