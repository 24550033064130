const extractFormValues = (e, keys) => {
  const content = {};
  for (const k of keys) {
    if (!e.target[k].value || e.target[k].value === 'null') {
      content[k] = null
    } else {
      content[k] = e.target[k].value
    }
  }
  return content;
}

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);                    

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-512', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string                  
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export { extractFormValues, sha256 };