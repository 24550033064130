import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { API_URL } from "../../config";
import { CSVLink } from "react-csv";

function JudgePage() {

  const [rows, setRows] = useState([]);

  const getJudges = () => {
    fetch(API_URL + "/judges", {
        method: 'GET',
    })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {

          } else {
            setRows(data)
          }
        });
  }

  useEffect(() => {
    getJudges()
  }, [])

  const generateURL = (id, token) => {
    return `http://debate.kuro.tech/judge/${id}?token=${token}`
  }

  const generateCsvData = () => {
    return rows.map((row) => ({
        姓名: row.name,
        学校: row.school,
        后台地址: generateURL(row.id, row.token)
      }
    ))
  }


  return (
    <Box sx={{
      '> div': {
        m: 1
      }
    }}>
      <CSVLink data={generateCsvData()} filename={"judges.csv"}>下载CSV文件</CSVLink>
      <TableContainer component={Paper}>
        <Table aria-label="member table">
          <TableHead>
            <TableRow>
              <TableCell>人员ID</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>学校</TableCell>
              <TableCell>后台地址</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.school}</TableCell>
                <TableCell>
                  {generateURL(row.id, row.token)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default JudgePage;