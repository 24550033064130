import React from 'react';
import { AppBar, Toolbar, Typography, Button, Grid, Card, CardContent, CardActions, Container } from '@mui/material';

const mockDebateSchedules = [
  {
    id: '初赛A1',
    matchTime: '2023-04-15 14:00',
    topic: '科技发展是否应该牺牲隐私',
    affirmativeTeam: '队伍A',
    negativeTeam: '队伍B',
    score: '3:2',
  },
  {
    id: '复赛A1',
    matchTime: '2023-04-16 10:00',
    topic: '学校教育是否应该包括财务管理课程',
    affirmativeTeam: '队伍C',
    negativeTeam: '队伍D',
    score: '未公布',
  },
  // 更多辩论赛安排
];


const TeamDebatePage = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            辩论赛安排
          </Typography>
          <Button color="inherit" href="/dashboard">仪表盘</Button>
          <Button color="inherit" href="/team/member">队员管理</Button>
          <Button color="inherit" href="/team/debate">比赛管理</Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          辩论赛安排
        </Typography>
        <Grid container spacing={3}>
          {mockDebateSchedules.map((schedule) => (
            <Grid item xs={12} sm={6} md={4} key={schedule.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">场次号: {schedule.id}</Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">比赛时间: {schedule.matchTime}</Typography>
                  <Typography variant="body2">辩题: {schedule.topic}</Typography>
                  <Typography variant="body2">正方队伍: {schedule.affirmativeTeam}</Typography>
                  <Typography variant="body2">反方队伍: {schedule.negativeTeam}</Typography>
                  <Typography variant="body2">得分: {schedule.score}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href='/team/debate/detail'>查看详情</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default TeamDebatePage;
