import { ExpandMore, Home } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  FormControl,
  InputLabel,
  NativeSelect,
  Button,
  Grid,
  Paper,
  Typography,
  Pagination,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams, useSearchParams, NavLink, Outlet } from "react-router-dom";

import { API_URL } from "../../config";

function Contestants() {
  const [rows, setRows] = useState([]);
  const [expanded, setExpanded] = useState(null)

  useEffect(() => {
    fetch(API_URL + "/public/team-members", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        // grouping data by teams
        const groupByTeam = data.reduce((r, a) => {
          r[a.teamName] = r[a.teamName] || [];
          r[a.teamName].push(a);
          return r;
        }, Object.create(null));
        setRows(groupByTeam);
      });
  }, []);

  const handleClick = (i) => {
    setExpanded(expanded === i ? null : i)
  }

  return (
    <Box>
      <div style={{
        position: 'fixed',
        backgroundColor: "#E7EBF0",
        height: '100%',
        width: '100%'
      }}></div>
      <Grid
        container
        alignItems="center"
        sx={{ height: "100vh", p: 1 }}
      >
        <div style={{ margin: 'auto' }}>
          {Object.keys(rows).map((teamName, i) => (
            <Accordion key={i} expanded={expanded === i} onChange={(e) => handleClick(i)}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <span>{teamName}</span>
              </AccordionSummary>
              <AccordionDetails>
                  <TableContainer sx={{ maxWidth: 700, whiteSpace: 'nowrap' }}>
                  <Table aria-label="contestants table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell><b>所属队伍</b></TableCell> */}
                        <TableCell align="left"><b>队伍成员</b></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"><b>学校</b></TableCell>
                        <TableCell align="left"><b>年级</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows[teamName].map((member, j) => (
                        <TableRow
                          key={j}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {member.name}
                          </TableCell>
                          {/* <TableCell align="right">{row.name}</TableCell> */}
                          <TableCell align="left">{member.role}</TableCell>
                          <TableCell align="left">{member.school}</TableCell>
                          <TableCell align="left">{member.classYear}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        {/* <TableContainer component={Paper} sx={{ maxWidth: 700, whiteSpace: 'nowrap' }}>
          <Table stickyHeader aria-label="contestants table">
            <TableHead>
              <TableRow>
                <TableCell><b>所属队伍</b></TableCell>
                <TableCell align="right"><b>参赛者</b></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"><b>学校</b></TableCell>
                <TableCell align="left"><b>年级</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.teamName}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="left">{row.role}</TableCell>
                  <TableCell align="left">{row.school}</TableCell>
                  <TableCell align="left">{row.classYear}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Grid>
    </Box>
  );
}

export default Contestants;
