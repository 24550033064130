import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";
import { sha256 } from "../../utils";

function LoginPage() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const rolePage = {
    评委: '/judge/home',
  }

  const authenticate = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;
    
    fetch(API_URL + `/auth/salt?email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: 'error' })
        } else {
          const salt = data.salt;
          sha256(password + salt)
            .then((saltedPassword) => {
              fetch(API_URL + "/auth/login", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({ email, password: saltedPassword }),
              })
                .then(async (response) => {
                  const data2 = await response.json()
                  if (!response.ok) {
                    const error = (data2 && data2.error) || response.status
                    enqueueSnackbar(error, { variant: 'error' })
                  } else {
                    enqueueSnackbar('登录成功', { variant: 'success' });
                    console.log('navigate to role page', data2, rolePage[data2.role]);
                    navigate(rolePage[data2.role]);
                  }
                })
                .catch((error) => {
                  enqueueSnackbar(error.message, { variant: 'error' })
                });
            })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      });
    
    
    
    e.target.reset();
  };

  return (
    <Paper
    sx={{
      p: 5,
      maxWidth: 400,
      textAlign: 'center',
  }}
    >
      <Divider>创辩后台登录</Divider>
      <form onSubmit={authenticate}>
        <Stack spacing={2}>
          <TextField
            id="email"
            label="邮箱"
            variant="outlined"
            required
          />
          <TextField
            id="password"
            label="密码"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            required
          />
          <Button type="submit" variant="contained">
            登录
          </Button>
          <Button
            variant="text"
            onClick={() => navigate('/auth/reset')}
          >
            通过邮箱设置或重置密码
          </Button>
        </Stack>
      </form>
    </Paper>

  );
}

export default LoginPage;
