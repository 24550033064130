import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Input,
  InputLabel,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";

function DebaterScorePage() {

  const [rows, setRows] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getScores = () => {
    fetch(API_URL + "/evaluations/debaters", {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            return [];
          } else {
            return setRows(data);
          }
        });
  }

  useEffect(() => {
    getScores()
  }, [])

  const deleteScore = (row) => {
    fetch(API_URL + `/evaluations/${row.debateId}/judge/${row.judgeId}/debater/${row.debaterId}/index/${row.index}`, {
      method: 'DELETE'
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: "error" });
        } else {
          enqueueSnackbar("分数删除成功", { variant: "success" });
          getScores()
        }
      });
  }

  return (
    <Box sx={{
      '> div': {
        m: 1
      }
    }}>
      <TableContainer component={Paper}>
        <Table aria-label="team table">
          <TableHead>
            <TableRow>
              <TableCell>赛事ID</TableCell>
              <TableCell>评委ID</TableCell>
              <TableCell>辩手ID</TableCell>
              <TableCell>分数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.debateId}</TableCell>
                <TableCell>{row.judgeId}</TableCell>
                <TableCell>{row.debaterId}</TableCell>
                <TableCell>{row.score}</TableCell>
                <TableCell>
                  <Button variant='text' color="error" onClick={() => deleteScore(row)}>
                    删除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DebaterScorePage;
