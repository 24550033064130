import {
  Box, 
  Divider, 
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink, Outlet } from "react-router-dom";
import TeamPage from "./TeamManage";
import MemberPage from "./MemberPage";
import DebatePage from "./DebatePage";
import { API_URL } from "../config";
import "./index.css"
import { useEffect, useState } from "react";

function Admin() {

  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    isLoggedIn()
  }, [])

  const isLoggedIn = () => {
    fetch(API_URL + "/login", {
      method: 'GET',
    })
      .then((response) => {
        setLoggedIn(response.ok)
      })
  }

  return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Drawer
          sx={{
            width: 240,
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <List>
            <ListItem>
              {loggedIn ? <div><CheckIcon color="success" />已登录</div> : <div><CloseIcon color="error" />未登录</div>}
            </ListItem>
            <Divider />
          {[
            {
              name: '登录',
              path: 'login'
            },
            {
              name: '队伍管理',
              path: 'team'
            },
            {
              name: '参赛者管理',
              path: 'member'
            },
            {
              name: '辩论赛管理',
              path: 'debate'
            },
            // {
            //   name: '评委后台链接',
            //   path: 'judge'
            // },
            {
              name: '评委值评时间段',
              path: 'timeslot'
            },
            {
              name: '评委值评时间段名额',
              path: 'timeslot-capacity'
            },
            {
              name: '最佳辩手评分',
              path: 'debater-score'
            },
            {
              name: '队伍评分',
              path: 'team-score'
            },
          ].map((item, index) => (
            <ListItem key={index} disablePadding>
              <NavLink
                to={item.path}
                style={{
                  textDecoration: 'none', 
                  color: 'black',
                  width: '100%' 
                }}
                children={({ isActive }) =>
                  <ListItemButton selected={isActive}>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                }
              >
              </NavLink>
            </ListItem>
          ))}
          </List>
        </Drawer>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
          }}
        >
          <Outlet context={{ setLoggedIn }} />
        </Box>
      </Box>
  );
}

export default Admin;