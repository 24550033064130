import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Input,
  InputLabel,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";
import { sha256 } from "../utils";
import { useOutletContext } from "react-router-dom";

function LoginPage() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoggedIn } = useOutletContext()

  const authenticate = (e) => {
    e.preventDefault();
    const username = e.target['username'].value;
    const password = e.target['password'].value;
    
    fetch(API_URL + `/salt/${username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: 'error' })
        } else {
          const salt = data[0].salt;
          sha256(password + salt)
            .then((saltedPassword) => {
              fetch(API_URL + "/login", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password: saltedPassword }),
              })
                .then(async (response) => {
                  if (!response.ok) {
                    const data = await response.json()
                    const error = (data && data.error) || response.status
                    enqueueSnackbar(error, { variant: 'error' })
                  } else {
                    enqueueSnackbar('登录成功', { variant: 'success' })
                    setLoggedIn(true)
                  }
                })
                .catch((error) => {
                  enqueueSnackbar(error.message, { variant: 'error' })
                });
            })
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      });
    
    e.target.reset();
  };

  return (
    <Box
      sx={{
        "> div": {
          m: 1,
        },
      }}
    >
      <Paper
        sx={{
          display: "block",
          p: 3,
        }}
      >
        <Typography variant="h5">登录</Typography>
        <Stack component="form" spacing={1} onSubmit={authenticate}>
          <TextField id="username" label="用户名" variant="standard" required />
          <TextField id="password" label="密码" type="password" variant="standard" required />
          <Button type="submit" variant="outlined">
            登录
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}

export default LoginPage;
