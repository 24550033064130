import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Timer from './Timer';

import Admin from './Admin';
import TeamPage from './Admin/TeamManage';
import MemberPage from './Admin/MemberPage';
import DebatePage from './Admin/DebatePage';
import JudgePage from './Admin/JudgePage';
import SaltGenerate from './Admin/SaltGenerate';
import LoginPage from './Admin/Login';
import TimeSlotPage from './Admin/TimeSlotPage';

import Home from './Home';

import JudgeHomePage from './Judge/Home';
import JudgeFramePage from './Judge';
import EvaluationPage from './Judge/Evaluation';

import { SnackbarProvider } from 'notistack';
import TimeSelect from './Judge/TimeSelect';
import DebaterScorePage from './Admin/DebaterScorePage';
import TeamScorePage from './Admin/TeamScorePage';
import Contestants from './Home/Contestants';
import TimeSlotAvailability from './Admin/TimeSlotAvailabilityPage';
import VolunteerPage from './Volunteer';
import Timer2 from './Timer2';
import Timer3 from './Timer3';
import Timer4 from './Timer4';
import Timer5 from './Timer5';
import Timer6 from './Timer6';
import Timer7 from './Timer7';
import Timer8 from './Timer8';
import Timer9 from './Timer9';
import Timer10 from './Timer10';
import TeamRegisterPage from './Team/RegisterPage';
import ConfirmationPage from './Team/RegisterPage/confirmation';
import TeamMemberPage from './Team/Member';
import TeamDebatePage from './Team/Debate';
import TeamDebateDetailPage from './Team/Debate/detail';
import AuthFramePage from './Auth';
import SendResetPasswordEmail from './Auth/ResetPassword/sendEmail';
import ResetPassword from './Auth/ResetPassword/resetPassword';
import UserLoginPage from './Auth/Login';


const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: '#11342d',
      paper: '#154f47'
    },
    text: {
      primary: '#ebd3ab',
    },
    primary: {
      main: '#ebd3ab',
    },
  },
  typography: {
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'Noto Serif',
    //   'SimSun',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary',
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
    }
  },
});

const defaultTheme = createTheme();

const router = createBrowserRouter([
  // {
  //   path: "/timer",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer2",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer2 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer3",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer3 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer4",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer4 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer5",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer5 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer6",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer6 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  // {
  //   path: "/timer7",
  //   element: <>
  //     <div className='blur-effect' />
  //     <ThemeProvider theme={theme}>
  //       <CssBaseline />
  //       <Timer7 />
  //     </ThemeProvider>
  //   </>,
  // }, 
  {
    path: "/timer8",
    element: <>
      <div className='blur-effect' />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Timer8 />
      </ThemeProvider>
    </>,
  },
  {
    path: "/timer9",
    element: <>
      <div className='blur-effect' />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Timer9 />
      </ThemeProvider>
    </>,
  },
  {
    path: "/timer10",
    element: <>
      <div className='blur-effect' />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Timer10 />
      </ThemeProvider>
    </>,
  },
  {
    path: "/",
    element: <>
      <div className='blur-effect' />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Home />
      </ThemeProvider>
    </>,
    errorElement: <h1>404 Not Found</h1>,
  },
  {
    path: "/contestants",
    element: <Contestants />
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin/team",
        element: <TeamPage />,
      },
      {
        path: "/admin/member",
        element: <MemberPage />,
      },
      {
        path: "/admin/debate",
        element: <DebatePage />,
      },
      {
        path: "/admin/judge",
        element: <JudgePage />,
      },
      {
        path: "/admin/login",
        element: <LoginPage />
      },
      {
        path: '/admin/timeslot',
        element: <TimeSlotPage />
      },
      {
        path: '/admin/timeslot-capacity',
        element: <TimeSlotAvailability />
      },
      {
        path: '/admin/debater-score',
        element: <DebaterScorePage />
      },
      {
        path: '/admin/team-score',
        element: <TeamScorePage />
      },
    ]
  },
  {
    path: "/judge",
    element: <JudgeFramePage />,
    children: [
      {
        path: "/judge/:judgeId/evaluation",
        element: <EvaluationPage />
      },
      {
        path: "/judge/home",
        element: <JudgeHomePage />
      },
      {
        path: "/judge/:judgeId/timeslots",
        element: <TimeSelect />
      }
    ],
  },
  {
    path: "/salt",
    element: <SaltGenerate />
  },
  {
    path: '/volunteer',
    element: <VolunteerPage />
  },
  {
    path: "/team",
    children: [
      {
        path: "/team/register",
        element: <TeamRegisterPage />,
      },
      {
        path: "/team/register/confirmation",
        element: <ConfirmationPage />,
      },
      {
        path: "/team/member",
        element: <TeamMemberPage />,
      },
      {
        path: "/team/debate",
        element: <TeamDebatePage />,
      },
      {
        path: "/team/debate/detail",
        element: <TeamDebateDetailPage />,
      }
    ]
  },
  {
    path: "/auth",
    element: <AuthFramePage />,
    children: [
      {
        path: "/auth/login",
        element: <UserLoginPage />
      },
      {
        path: "/auth/reset",
        element: <SendResetPasswordEmail />,
      },
      {
        path: "/auth/reset/:token",
        element: <ResetPassword />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
