import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Input,
  InputLabel,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { API_URL } from "../../config";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";
import { CSVLink } from "react-csv";
import { TEAM_GROUP_MAP } from "../../encoding";

const getTeams = () => {
  return fetch(API_URL + "/teams", {
    method: 'GET',
  })
    .then(async (response) => {
      if (!response.ok) {
        return []
      } else {
        const data = await response.json();
        return data
      }
    })
}

function TeamPage() {

  const [rows, setRows] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateTeams = () => {
    getTeams()
      .then((data) => {
        setRows(data)
      })
  }

  useEffect(() => {
    updateTeams()
  }, [])

  const createTeam = (e) => {
    e.preventDefault();
    fetch(API_URL + "/teams", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: e.target['name'].value, group: e.target['group'].value })
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: 'error' });
        } else {
          enqueueSnackbar("队伍创建成功", { variant: 'success' });
          updateTeams();
        }
      })
      .catch((error) => {
        enqueueSnackbar("队伍创建失败", { variant: 'error' });
      });
    // e.target.reset();
  }

  const deleteTeam = (id) => {
    fetch(API_URL + `/teams/${id}`, {
      method: 'DELETE'
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: 'error' });
        } else {
          enqueueSnackbar("队伍已删除", { variant: 'success' });
          updateTeams();
        }
      })
      .catch((error) => {
        enqueueSnackbar("队伍删除失败", { variant: 'error' });
      });
  }

  const editTeam = (e) => {
    e.preventDefault();
    const id = e.target['id'].value;
    fetch(API_URL + `/teams/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: e.target['name'].value, group: e.target['group'].value })
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          const error = (data && data.error) || response.status;
          enqueueSnackbar(error, { variant: 'error' });
        } else {
          enqueueSnackbar("队伍更新成功", { variant: 'success' });
          updateTeams();
        }
      })
      .catch((error) => {
        enqueueSnackbar("队伍更新失败", { variant: 'error' });
      });
    setOpenEditDialog(false);
  }

  const openEditTeam = (row) => {
    setFormDefaultValues(row);
    setOpenEditDialog(true);
  }

  return (
    <Box sx={{
      '> div': {
        m: 1
      }
    }}>
      <CSVLink data={rows} filename={"teams.csv"}>下载CSV文件</CSVLink>
      <TableContainer component={Paper}>
        <Table aria-label="team table">
          <TableHead>
            <TableRow>
              <TableCell>队伍ID</TableCell>
              <TableCell>队伍名称</TableCell>
              <TableCell>组别</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.group}</TableCell>
                <TableCell>
                  <Button variant='text' onClick={() => openEditTeam(row)}>
                    编辑
                  </Button>
                  <Button variant='text' color="error" onClick={() => deleteTeam(row.id)}>
                    删除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{
        display: 'block',
        p: 3,
        maxWidth: 500
      }}>
        <Typography variant="h5">创建队伍</Typography>
        <Stack component="form" spacing={1} onSubmit={createTeam}>
          <FormControl>
            <RadioGroup row aria-labelledby="group" name="group" required>
              {Object.entries(TEAM_GROUP_MAP).map(([key, value]) => (
                <FormControlLabel value={key} control={<Radio />} label={value} />
              ))}
            </RadioGroup>
          </FormControl>
          <TextField
            id="name"
            label="队伍名称"
            variant="standard"
            required
          />
          <Button type="submit" variant='outlined'>提交</Button>
        </Stack>
      </Paper>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>编辑队伍</DialogTitle>
        <form onSubmit={editTeam}>
          <DialogContent>
            <TextField
              disabled
              id="id"
              label="队伍ID"
              type="text"
              defaultValue={formDefaultValues?.id}
            />
            <TextField
              id="name"
              label="队伍名称"
              type="text"
              defaultValue={formDefaultValues?.name}
              required
            />
            <FormControl>
              <RadioGroup row aria-labelledby="group" name="group" required defaultValue={formDefaultValues?.group}>
                {Object.entries(TEAM_GROUP_MAP).map(([key, value]) => (
                  <FormControlLabel value={key} control={<Radio />} label={value} />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit">确认</Button>
            <Button onClick={() => setOpenEditDialog(false)}>
              取消
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export { getTeams };
export default TeamPage;
