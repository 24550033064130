import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

function Time({ sec, size }) {

  return (
    <div className="Timer">
      <Typography variant={size}>
        {Math.floor(sec / 60)} : {sec % 60 < 10 ? '0' + sec % 60 : sec % 60}
      </Typography>
    </div>
  );
}

export default Time;
